import React from "react"

import { Link } from "gatsby"

import footerStyles from "../styles/footer.module.scss"

const Footer = () => {
  return (
    <footer>
      <span className={footerStyles.copyright}>
        © {new Date().getFullYear()},{` `}
        Crazy Critters Experience
      </span>
      <span className={footerStyles.terms}>
        <Link to={`/terms/`}>Terms & Conditions</Link>
        <span>&nbsp;|&nbsp;</span>
        <Link to={`/privacypolicy/`}>Privacy Policy</Link>
      </span>
    </footer>
  )
}

export default Footer
