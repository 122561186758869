import { Link } from "gatsby"
import React from "react"

import DrawerToggleButton from "../components/SideDrawer/DrawerToggleButton"
import NavigationLinks from "./navigation-links"
import headerStyles from "../styles/header.module.scss"

import mobileLogo from "../images/logo-head-small.svg"
import Logo from "../assets/logo_with_text_omg.svg"

const Header = props => {
  return (
    <header className={`header ${headerStyles.header}`}>
      <div className={headerStyles.headerInternalWrap}>
        <DrawerToggleButton click={props.drawerClickHandler} />
        <Link to="/" className={headerStyles.siteLogo}>
          <Logo className={headerStyles.siteLogoSVG} />
        </Link>
        <div className={headerStyles.siteTitleMobile}>
          <Link to="/">
            <h2>Crazy Critters Experience</h2>
            <img src={mobileLogo} alt="logo for mobile" />
          </Link>
        </div>

        <nav>
          <NavigationLinks className={headerStyles.nav} />
        </nav>
      </div>
    </header>
  )
}

export default Header
