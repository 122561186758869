import React from "react"
import { Link } from "gatsby"

import navStyles from "../styles/nav.module.scss"

const navigationLinks = () => (
  <ul>
    {/* <li>
      <Link to="/">
        <h3>About</h3>
      </Link>
    </li> */}
    {/* <div className={navStyles.line} /> */}
    <li>
      <Link to="/services/">
        <h3>Services</h3>
      </Link>
    </li>
    <div className={navStyles.line} />
    <li>
      <Link to="/animals/">
        <h3>Meet The Critters</h3>
      </Link>
    </li>
    <div className={navStyles.line} />
    <li>
      <Link to="/gallery/">
        <h3>Gallery</h3>
      </Link>
    </li>
    <div className={navStyles.line} />
    <li>
      <Link to="/reviews/">
        <h3>Reviews</h3>
      </Link>
    </li>
    <div className={navStyles.line} />
    <li>
      <Link to="/contact/">
        <h3>Contact Us</h3>
      </Link>
    </li>
  </ul>
)

export default navigationLinks
