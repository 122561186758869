import React from "react"

import toggleButtonStyles from "../../styles/toggleButton.module.scss"

const drawerToggleButton = props => (
  <button className={toggleButtonStyles.button} onClick={props.click}>
    <div className={toggleButtonStyles.line} />
    <div className={toggleButtonStyles.line} />
    <div className={toggleButtonStyles.line} />
  </button>
)

export default drawerToggleButton
