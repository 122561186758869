import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import SideDrawer from "./SideDrawer"
import BackDrop from "./back-drop"
import Footer from "./footer"
import "../styles/global.css"
import layoutStyles from "../styles/layout.module.scss"

class Layout extends React.Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  }

  sideDrawerCloseHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }

  render() {
    let backDrop

    if (this.state.sideDrawerOpen) {
      // sideDrawer = (
      //   <SideDrawer sideDrawerCloseHandler={this.sideDrawerCloseHandler} />
      // )

      backDrop = <BackDrop click={this.sideDrawerCloseHandler} />
    }
    return (
      <>
        <div className={layoutStyles.container}>
          <Header drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer
            show={this.state.sideDrawerOpen}
            close={this.sideDrawerCloseHandler}
          />
          {backDrop}
          <div className={layoutStyles.pageWrap}>
            <main className={`main ${layoutStyles.main}`}>
              {this.props.children}
            </main>
            <Footer />
          </div>
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
