import React from "react"

import Navigation from "../navigation-links"
import "./sideDrawer.scss"

const sideDrawer = props => {
  let drawerClasses = "drawer"
  if (props.show) {
    drawerClasses = "drawer open"
  }
  console.log(props)
  return (
    <>
      <nav className={drawerClasses}>
        <button className="close" onClick={props.close}>
          <svg
            viewport="0 0 30 30"
            width="30"
            height="30"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="29"
              x2="29"
              y2="1"
              stroke="rgba(58, 58, 58, 1)"
              strokeWidth="3"
            />
            <line
              x1="1"
              y1="1"
              x2="29"
              y2="29"
              stroke="rgba(58, 58, 58, 1)"
              strokeWidth="3"
            />
          </svg>
        </button>
        <Navigation />
      </nav>
    </>
  )
}

export default sideDrawer
